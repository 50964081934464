<script>
import Layout from "../../layouts/main.vue";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  components: { Layout },
  data() {
    return {
      doctor: {
        val: "",
        isValid: true,
      },
      day: {
        val: "",
        isValid: true,
      },
      excludedDate: {
        val: "",
        isValid: true,
      },
      startTime: {
        val: "",
        isValid: true,
      },
      endTime: {
        val: "",
        isValid: true,
      },
      consultingTime: {
        val: "",
        isValid: true,
      },
      status: {
        val: "",
        isValid: true,
      },
      msg: "",
      id: "",
      formIsValid: true,
      availability: [],
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      if (this.doctor.val === "") {
        this.doctor.isValid = false;
        this.formIsValid = false;
      }
      if (this.day.val === "") {
        this.day.isValid = false;
        this.formIsValid = false;
      }
      // if (this.excludedDate.val === "") {
      //   this.excludedDate.isValid = false;
      //   this.formIsValid = false;
      // }
      if (this.startTime.val === "") {
        this.startTime.isValid = false;
        this.formIsValid = false;
      }
      if (this.endTime.val === "") {
        this.endTime.isValid = false;
        this.formIsValid = false;
      }
      if (this.consultingTime.val === "") {
        this.consultingTime.isValid = false;
        this.formIsValid = false;
      }
      if (this.status.val === "") {
        this.status.isValid = false;
        this.formIsValid = false;
      } else {
        this.formIsValid = true;
      }
    },
    async getData() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctor/"
      );
      this.doctor = response.data;
      console.log(this.doctor);
    },
    async getAvailability() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/doctors/schedule"
      );
      this.availability = response.data;
      const lastEl = this.availability[this.availability.length - 1];
      this.id = lastEl.id;
      this.successmsg(this.id);
    },
    async addAvailability() {
      // let docday = JSON.stringify(this.day.val)
      // console.log(docday)
      //   docValue.forEach((element) => {
      //     console.log(element);
      //   });
// let docdoc = JSON.stringify(this.doctor.val)
// console.log(docdoc)
   
      this.validateForm();
      if (!this.formIsValid) {
        this.errormsg();
        return;
      } else {
        var formdata = new FormData();
        // let docName = JSON.stringify(this.doctor.val)
        formdata.append("doctors", JSON.stringify(this.doctor.val));
        formdata.append("day", JSON.stringify([this.day.val]));
        formdata.append(
          "excludedDate",
          JSON.stringify([this.excludedDate.val])
        );
        formdata.append("startTime", this.startTime.val);
        formdata.append("endTime", this.endTime.val);
        formdata.append("consultingTime", this.consultingTime.val);
        formdata.append("status", this.status.val);
        const result = await axios.post(
          "https://api.doctosoft.com/index.php/api/doctors/schedule",
          formdata
        );
        console.log(result);
        this.msg = result.data;
        this.getAvailability();
        await this.$router.push("/admin/availability");
      }
    },
    errormsg() {
      Swal.fire(
        "Invalid Form",
        "Please fix the errors and try again later",
        "error"
      );
    },
    successmsg(id) {
      Swal.fire(
        "Good job!",
        "Created new Schedule successfully with id " + id,
        "success"
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>

<template>
  <Layout>
    <!-- <h1>Add Availability</h1> -->
    <div class="back-btn">
      <button
        class="btn btn-dark btn-sm"
        @click="$router.push('/admin/availability')"
      >
        Back
      </button>
    </div>
    <form class="needs-validation" @submit.prevent="addAvailability">
      <div class="mb-3" :class="{ invalid: !doctor.isValid }">
        <label for="doctors" class="form-label"
          >Doctors <span class="text-danger">*</span></label
        >
        <select
          class="form-select"
          multiple
          aria-label="multiple select example"
          v-model="doctor.val"
          @blur="clearValidity('doctor')"
        >
          <option :value="doc.id" v-for="doc in doctor" :key="doc.id">
            {{ doc.name }}
          </option>
        </select>
      </div>
      <div class="mb-3">
        <label for="excludedDate" class="form-label">Excluded Date <span class="text-danger">(optional)</span></label>
        <input
          type="text"
          name="excludedDate"
          id="excludedDate"
          class="form-control"
          placeholder="Excluded Date"
          v-model.trim="excludedDate.val"
        />
      </div>
      <div class="mb-3" :class="{ invalid: !day.isValid }">
        <label for="day" class="form-label"
          >Day <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="day"
          id="day"
          class="form-control"
          placeholder="Day"
          v-model.trim="day.val"
          @blur="clearValidity('day')"
        />
      </div>
      <div class="mb-3" :class="{ invalid: !startTime.isValid }">
        <label for="startTime" class="form-label"
          >Start Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="startTime"
          id="startTime"
          class="form-control"
          placeholder="Start Time"
          v-model.trim="startTime.val"
          @blur="clearValidity('startTime')"
        />
      </div>
      <div class="mb-3" :class="{ invalid: !endTime.isValid }">
        <label for="endTime" class="form-label"
          >End Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="endTime"
          id="endTime"
          class="form-control"
          placeholder="End Time"
          v-model.trim="endTime.val"
          @blur="clearValidity('endTime')"
        />
      </div>
      <div class="mb-3" :class="{ invalid: !consultingTime.isValid }">
        <label for="consultingTime" class="form-label"
          >Consulting Time <span class="text-danger">*</span></label
        >
        <input
          type="text"
          name="consultingTime"
          id="consultingTime"
          class="form-control"
          placeholder="Consulting Time"
          v-model.trim="consultingTime.val"
          @blur="clearValidity('consultingTime')"
        />
      </div>
      <div class="mb-3" :class="{ invalid: !status.isValid }">
        <label for="statusAvailability" class="form-label"
          >Status <span class="text-danger">*</span></label
        >
        <input
          name="statusAvailability"
          id="statusAvailability"
          type="text"
          class="form-control"
          placeholder="Enter status"
          v-model.trim="status.val"
          @blur="clearValidity('status')"
        />
      </div>

      <div class="mt-4">
        <b-button variant="success" class="w-100" type="submit">Add</b-button>
      </div>
    </form>
  </Layout>
</template>

<style>
.back-btn {
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
}
.invalid label {
  color: #f06548;
}

.invalid input {
  border: 1px solid #f06548;
}
.invalid select {
  border: 1px solid #f06548;
}
</style>
